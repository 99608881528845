
import { apiCall } from '../../components/api/v1/calls';

const state = {
  status: null,
  groups: null,
  selectedGroup: null,
  gateways: null,
  selectedGateway: null,
  ports: {
    available: null,
    active: null,
  },
  dialogGroup: false,
};

const getters = {
  authStatus: state => state.status,
};

const actions = {
  getGroups: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/GetDialOutGroups', method: 'GET', data: null})
        .then(resp => {
          commit('status', 'success');
          // console.log('getGroups - resp:', resp);
          let groups = Array.isArray(resp.data.result) ? resp.data.result : null;
          if (groups && groups.length > 1) {
            groups = groups.sort((a, b) => a.description === b.description ? 0 : a.description < b.description ? -1 : 1);
            commit('setSelectedGroup', groups[0].code);
          }
          commit('groups', groups);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
  addGroup: ({commit}, {description}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/CreateDialOutGroup', method: 'POST', data: {
          description,
          is_default: false,
        }})
        .then(resp => {
          commit('status', 'success');
          // console.log('addGroup - resp:', resp);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
  deleteGroup: ({commit}, {code}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/DelDialOutGroup/' + code, method: 'DELETE', data: null})
        .then(resp => {
          commit('status', 'success');
          console.log('deleteGroup - resp:', resp);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
  getGateways: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({endpoint: '/customer/GsmGateways', method: 'GET', data: null})
        .then(resp => {
          commit('status', 'success');
          // console.log('getGateways - resp:', resp);
          let getaways = Array.isArray(resp.data.result) ? resp.data.result : null;
          if (getaways) {
            getaways = getaways.sort((a, b) => a.alias === b.alias ? 0 : a.alias < b.alias ? -1 : 1);
          }
          commit('gateways', getaways);
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
  getPorts: async ({state, commit}, options) => {
    const {
      selectedGroup,
      selectedGateway,
      gateways,
    } = state;

    if (!selectedGroup) {
      return false;
    }

    commit('status', 'loading');

    const gatewaysArray = selectedGateway
      ? [selectedGateway]
      : gateways.map(i => i.username);

    const sortPorts = (a, b) => a.username === b.username ? 0 : a.username < b.username ? -1 : 1;

    const getGatewayAvailablePorts = (gateway) => {
      return new Promise((resolve, reject) => {
        const data = {
          group_code: selectedGroup,
          gsm_gateway: gateway,
          bounded: false,
        };
        apiCall({
          endpoint: '/customer/GetGsmGatewayPortsGroupBinding', method: 'POST', data,
        })
          .then(resp => {
            commit('status', 'success');
            // console.log('getPorts - availablePorts - resp:', resp);
            let ports = Array.isArray(resp.data.result) ? resp.data.result : null;
            if (ports) {
              ports = ports.sort(sortPorts);
            }
            resolve(ports);
          })
          .catch(err => {
            console.log('getPorts - availablePorts - err:', err);
            reject(err);
          });
      });
    };

    const getGatewayActivePorts = (gateway) => {
      return new Promise((resolve, reject) => {
        commit('status', 'loading');
        const data = {
          group_code: selectedGroup,
          gsm_gateway: gateway,
          bounded: true,
        };
        apiCall({
          endpoint: '/customer/GetGsmGatewayPortsGroupBinding', method: 'POST', data,
        })
          .then(resp => {
            commit('status', 'success');
            // console.log('getPorts - activePorts - resp:', resp);
            let ports = Array.isArray(resp.data.result) ? resp.data.result : null;
            if (ports) {
              ports = ports.sort(sortPorts);
            }
            resolve(ports);
          })
          .catch(err => {
            console.log('getPorts - activePorts - err:', err);
            reject(err);
          });
      });
    };

    const getGatewayPorts = async gateway => ({
      availablePorts: {
        gateway,
        ports: await getGatewayAvailablePorts(gateway),
      },
      activePorts: {
        gateway,
        ports: await getGatewayActivePorts(gateway),
      },
    });

    const getPorts = array => {
      if (options && options.gateway) {
        array = array.filter(gateway => gateway === options.gateway)
      }
      return Promise.all(array.map(getGatewayPorts));
    };

    let result = {
      availablePorts: [],
      activePorts: [],
    };

    for (const i of (await getPorts(gatewaysArray))) {
      for (const [key, value] of Object.entries(i)) {
        const { gateway } = value;

        const portsArray = value.ports.map(p => ({
          ...p,
          gateway,
        }));

        result[key].push({
          gateway,
          ports: portsArray,
        });
      }
    }

    if (options && options.gateway) {
      const ports = state.ports;
      const availablePortsIndex = ports.availablePorts.findIndex(o => o.gateway === options.gateway);
      const activePortsIndex = ports.activePorts.findIndex(o => o.gateway === options.gateway);
      ports.availablePorts[availablePortsIndex].ports =
        result.availablePorts[0].ports;
      ports.activePorts[activePortsIndex].ports =
        result.activePorts[0].ports;
      result = ports;
    }

    commit('ports', result);

    commit('status', 'success');

    return result;
  },
  setSelectedGroup({commit, dispatch}, value) {
    commit('setSelectedGroup', value);
    dispatch('getPorts');
  },
  setSelectedGateway({commit, dispatch}, value) {
    commit('setSelectedGateway', value);
    dispatch('getPorts');
  },
  togglePort({commit, dispatch}, {port_index, active, gateway}) {
    const apiRoute = active ? 'DelPortFromDialOutGroup' : 'AddPortToDialOutGroup';
    const apiMethod = active ? 'DELETE' : 'POST';
    const {
      selectedGroup,
    } = state;

    console.log(`togglePort - port_index, active, gateway:`, port_index, active, gateway);

    return new Promise((resolve, reject) => {
      commit('status', 'loading');
      apiCall({
        endpoint: `/customer/${apiRoute}`, method: apiMethod, data: {
          gsm_gateway: gateway,
          group_code: selectedGroup,
          port_index: port_index,
        },
      })
        .then(async resp => {
          commit('status', 'success');
          console.log(`${apiMethod} - resp:`, resp);
          await dispatch('getPorts', {port_index, active, gateway});
          resolve(resp);
        })
        .catch(err => {
          commit('status', 'error');
          reject(err);
        });
    });
  },
};

const mutations = {
  status: (state, payload) => {
    state.status = payload;
  },
  groups: (state, payload) => {
    state.groups = payload;
  },
  setSelectedGroup: (state, payload) => {
    state.selectedGroup = payload;
  },
  gateways: (state, payload) => {
    state.gateways = payload;
  },
  setSelectedGateway: (state, payload) => {
    state.selectedGateway = payload;
  },
  ports: (state, payload) => {
    state.ports = payload;
  },
  setDialogGroup: (state, payload) => {
    state.dialogGroup = payload;

    if (!payload) {
      state.ports = {
        available: null,
        active: null,
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
